import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ErrorHandlerService } from '../../../services/error-handler/error-handler.service';

@Injectable()
export class SalesLogsService {
  reportingServer = `${environment.vendingServer}/api/v1/reports/jasper/pdf`;

  httpOptions: any = {
    responseType: 'blob' as 'json',
  };

  constructor(private _http: HttpClient, private _errorHandler: ErrorHandlerService) {}

  getSalesLogs(
    start_date,
    end_date,
    agency_id,
    cashier_code,
    user_first_name,
    user_last_name,
  ): Observable<Blob> {
    const uri = `${
      this.reportingServer
    }/journal_des_ventes?agency_id=${agency_id}&start_date=${start_date}&end_date=${end_date}&code_caissier=${cashier_code}&journal_user_firstname=${user_first_name
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')}&journal_user_lastname=${user_last_name
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')}`;
    // @ts-ignore
    return this._http.get<Blob>(uri, this.httpOptions);
  }

  getAgencies(): Observable<any> {
    return this._http
      .get(`${environment.vendingServer}/api/v1/agencies/sales-journal`)
      .pipe(catchError(this._errorHandler.handleError));
  }
}
