import { Action } from '@ngrx/store';
import { Node } from '../models/node.model';

export const SELECT_NODE = '[NODE] SELECT_NODE';

export class SelectNode implements Action {
  readonly type = SELECT_NODE;

  constructor(public payload: Node) {}
}

export type Actions = SelectNode;
