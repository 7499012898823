import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from '../../../services/error-handler/error-handler.service';
import { environment } from '../../../../environments/environment';
import { getHttpOptions } from '../../../../utils/http-utils';

@Injectable()
export class VenteEnergieService {
  constructor(private _http: HttpClient, private _errorHandler: ErrorHandlerService) {}

  httpOptions = getHttpOptions();

  getInfosClient(meterNumber: string, montant: number): Observable<any> {
    return this._http
      .get(
        `${environment.vendingServer}/api/v1/sales/info?meter_id=${meterNumber}&amount=${montant}`,
        this.httpOptions,
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  getdetails(meterNumber: string, montant: number, paymentModeId: number): Observable<any> {
    return this._http
      .get(
        `${environment.vendingServer}/api/v1/sales/init?meter_id=${meterNumber}&amount=${montant}&payment_mode_id=${paymentModeId}`,
        this.httpOptions,
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  getToken(meterNumber: string, montant: number, sale_code: string): Observable<any> {
    return this._http
      .get(
        `${environment.vendingServer}/api/v1/sales/perform?meter_id=${meterNumber}&amount=${montant}&sale_code=${sale_code}`,
        this.httpOptions,
      )
      .pipe(catchError(this._errorHandler.handleError));
  }
}
