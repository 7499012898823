import { Action } from '@ngrx/store';
import { HeaderInfo } from '../models/header-info.model';

export const UPDATE_HEADER_INFO = '[HEADER] UPDATE_INFO';

export class UpdateInfo implements Action {
  readonly type = UPDATE_HEADER_INFO;

  constructor(public payload: HeaderInfo) {}
}

export type Actions = UpdateInfo;
