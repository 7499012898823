import { HeaderInfo } from '../models/header-info.model';
import * as HeaderActions from '../actions/header.actions';

const defaultInfo: HeaderInfo = {
  title: '',
  displayNode: false,
};

const initialState = {
  header_info: defaultInfo,
};

export function headerReducer(state = initialState, action: HeaderActions.Actions) {
  if (action.type === HeaderActions.UPDATE_HEADER_INFO) {
    return {
      ...state,
      header_info: action.payload,
    };
  }
  return state;
}
