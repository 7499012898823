import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ErrorHandlerService } from '../../../services/error-handler/error-handler.service';
import { environment } from '../../../../environments/environment';
import { getHttpOptions } from '../../../../utils/http-utils';

@Injectable()
export class CaisseService {
  constructor(private _http: HttpClient, private _errorHandler: ErrorHandlerService) {}

  httpOptions = getHttpOptions();

  getCashiers(agenceId: string): Observable<any> {
    return this._http
      .get(
        `${environment.vendingServer}/api/v1/agencies/${agenceId}/free-cashiers`,
        this.httpOptions,
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  affect(agenceId: string, cashierId: number, amount: any): Observable<any> {
    const body = {
      cashier_id: cashierId,
      agency_id: agenceId,
      cash_funds: amount,
    };

    return this._http
      .post<any>(
        `${environment.vendingServer}/api/v1/agencies/assign-cash-box`,
        body,
        this.httpOptions,
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  open(cashBoxId: string, cashBox: number, cashFund: any, comment: string): Observable<any> {
    const body = {
      assign_caisse_id: cashBoxId,
      caisse: cashBox,
      cash_funds: cashFund,
      comment,
    };

    return this._http
      .post<any>(
        `${environment.vendingServer}/api/v1/agencies/open-cash-box`,
        body,
        this.httpOptions,
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  stop(cashBoxId: string, balance: any, comment: string): Observable<any> {
    const body = {
      assign_caisse_id: cashBoxId,
      comment,
      balance,
    };

    return this._http
      .post<any>(
        `${environment.vendingServer}/api/v1/agencies/stop-cash-box`,
        body,
        this.httpOptions,
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  free(cashBoxId: string): Observable<any> {
    const body = {
      assign_caisse_id: cashBoxId,
    };

    return this._http
      .post<any>(
        `${environment.vendingServer}/api/v1/agencies/free-cash-box`,
        body,
        this.httpOptions,
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  getPaymentMode(): Observable<any> {
    return this._http
      .get(`${environment.vendingServer}/api/v1/payment_mode`, this.httpOptions)
      .pipe(catchError(this._errorHandler.handleError));
  }

  getAllAvailablePaymentMode(): Observable<any> {
    return this._http
      .get(`${environment.vendingServer}/api/v1/payment_mode?value=all`, this.httpOptions)
      .pipe(catchError(this._errorHandler.handleError));
  }

  show(caisseId): Observable<any> {
    return this._http
      .get(
        `${environment.vendingServer}/api/v1/agencies/cash-box-assignment-balance?assign_caisse_id=${caisseId}`,
        this.httpOptions,
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  getCashBoxList(agenceId: string): Observable<any> {
    return this._http
      .get(
        `${environment.vendingServer}/api/v1/agencies/${agenceId}/active-cash-boxes`,
        this.httpOptions,
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  getCashBox(): Observable<any> {
    return this._http
      .get(`${environment.vendingServer}/api/v1/agencies/current-cash-box`, this.httpOptions)
      .pipe(catchError(this._errorHandler.handleError));
  }

  getCashBoxBalance(caisseId: number): Observable<any> {
    return this._http
      .get(
        `${environment.vendingServer}/api/v1/agencies/cash-box-balance?assign_caisse_id=${caisseId}`,
        this.httpOptions,
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  getStopBalance(cashBoxId: string): Observable<any> {
    return this._http.get(
      `${environment.vendingServer}/api/v1/agencies/balance-at-closing?assign_caisse_id=${cashBoxId}`,
      this.httpOptions,
    );
  }

  getSessionDetails(session_id: string): Observable<any> {
    return this._http.get(
      `${environment.vendingServer}/api/v1/sale_sessions?sale_session_id=${session_id}`,
      this.httpOptions,
    );
  }

  closeCashBox(cashBoxId: string, balance: any, comment_at_close: string): Observable<any> {
    const body = {
      assign_caisse_id: cashBoxId,
      actual_balance_at_close: balance,
      comment: comment_at_close,
    };
    return this._http
      .post<any>(
        `${environment.vendingServer}/api/v1/agencies/close-cash-box`,
        body,
        this.httpOptions,
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  getUserList(): Observable<any> {
    return this._http
      .get(`${environment.vendingServer}/api/v1/users/list-users-agency`, this.httpOptions)
      .pipe(catchError(this._errorHandler.handleError));
  }

  getCashiersList(agenceId: string): Observable<any> {
    return this._http
      .get(`${environment.vendingServer}/api/v1/agencies/${agenceId}/cashiers`, this.httpOptions)
      .pipe(catchError(this._errorHandler.handleError));
  }

  addExternalCashier(data: any): Observable<any> {
    const body = {} as { [key: string]: any };

    // eslint-disable-next-line no-restricted-syntax
    for (const obj of data) {
      const key = Object.keys(obj)[0];
      const value = obj[key];
      body[key] = value;
    }

    body.action_type_code = 'create_extern_cashier';

    return this._http
      .post<any>(
        `${environment.vendingServer}/api/v1/users/create-extern-user`,
        body,
        this.httpOptions,
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  updateExternalUser(data): Observable<any> {
    const body = {} as { [key: string]: any };

    // eslint-disable-next-line no-restricted-syntax
    for (const obj of data) {
      const key = Object.keys(obj)[0];
      const value = obj[key];
      body[key] = value;
    }

    body.action_type_code = 'update_extern_cashier';

    return this._http
      .post<any>(
        `${environment.vendingServer}/api/v1/users/update-extern-user`,
        body,
        this.httpOptions,
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  deleteExternalUser(fuid): Observable<any> {
    const body = {
      fuid,
    };
    return this._http
      .post<any>(
        `${environment.vendingServer}/api/v1/agencies/delete-external-user`,
        body,
        this.httpOptions,
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  freeInternalCashier(id: number): Observable<any> {
    return this._http
      .post<any>(
        `${environment.vendingServer}/api/v1/cashiers/free-internal-cashier?id=${id}`,
        this.httpOptions,
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  searchUser(search: string): Observable<any> {
    return this._http
      .get<any>(
        `${environment.vendingServer}/api/v1/cashiers/users?search=${search}`,
        this.httpOptions,
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  assignUser(fuid: string): Observable<any> {
    const body = {
      fuid,
      action_type_code: 'create_intern_cashier',
    };
    return this._http
      .post<any>(`${environment.vendingServer}/api/v1/cashiers/assign-user`, body, this.httpOptions)
      .pipe(catchError(this._errorHandler.handleError));
  }

  getAllowRoles(): Observable<any> {
    return this._http
      .get<any>(`${environment.vendingServer}/api/v1/users/allowed-roles`, this.httpOptions)
      .pipe(catchError(this._errorHandler.handleError));
  }

  ReorderPaymentModes(agency_id: string, reorder: any[]) {
    const body = {
      agency_id,
      reorder,
      action_type_code: 'reorder_payment_mode',
    };

    return this._http
      .post<any>(
        `${environment.vendingServer}/api/v1/payment_mode/reorder-payment-mode`,
        body,
        this.httpOptions,
      )
      .pipe(catchError(this._errorHandler.handleError));
  }
}
