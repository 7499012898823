// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  baseUrl: window['env']['BASE_URL'],
  production: true,
  name: 'test',
  uipServer: window['env']['UIP_SERVER'],
  vendingServer: window['env']['VENDING_SERVER'],
  webSocketHost: window['env']['WEBSOCKET_HOST'],
  webSocketAuthUrl: window['env']['WEBSOCKET_AUTH_URL'],
  reportingServer: window['env']['REPORTING_SERVER'],
  apiPuaUrl: window['env']['API_PUA_URL'],
  language: 'fr',
  globalDomain: window['env']['GLOBAL_DOMAIN'],
  devise: window['env']['DEVISE'],
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
