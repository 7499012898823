import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AkiAuthService } from '@akilee/auth';

/**
 * Intercepteur qui déconnecte automatiquement l'utilisateur
 * dès qu'on a une réponse 401(Unauthorized) de la part d'un serveur
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private authService: AkiAuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          this.authService.logout();
          // eslint-disable-next-line no-restricted-globals
          location.reload();
        }
        const { error } = err;
        return throwError(error);
      }),
    );
  }
}
