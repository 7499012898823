<nb-layout center *transloco="let t">
  <nb-layout-column>

    <div class="bg-gray-200 h-screen flex flex-col items-center justify-center">

      <div class="logo-container h-32 w-72">
        <img src="assets/images/Logo-Akilee-00.png" alt="Logo de l'entreprise'" class="img-shadowed">
      </div>

      <form class="form-container bg-white shadow-lg p-8 rounded-lg" [formGroup]="loginForm">
        <p class="text-gray-600 text-xs italic">{{ t('login.placeholder') }} !</p>
        <div class="mt-6">
          <label class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2" for="email" [class.text-red-500]="email.touched && email.invalid">
            {{ t('login.id') }}
          </label>
          <input class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-400 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:border-blue-600 focus:bg-white" [class.border-red-500]="email.touched && email.invalid" formControlName="email" id="email" type="text" placeholder="exemple@domaine.com">
          <p class="text-red-500 text-xs italic" *ngIf="email.touched && email.invalid">{{ t('login.no_valid_email') }} !</p>
        </div>

        <div class="my-10">
          <button class="flex items-center justify-center shadow bg-blue-500 hover:bg-blue-600 w-full focus:outline-none text-white uppercase font-bold py-2 px-4 rounded disabled:cursor-not-allowed disabled:bg-gray-400 disabled:text-gray-600" (click)="onSubmit()" [attr.disabled]="(email.valid) ? null : ''">
            <div class="loader ease-linear rounded-full border-2 border-t-2 border-blue-400 h-8 w-8" *ngIf="isLoading"></div>
            <div class="h-8 flex items-center justify-center" *ngIf="!isLoading">{{ t('login.send') }}</div>
          </button>
        </div>
      </form>
    </div>
  </nb-layout-column>
</nb-layout>
