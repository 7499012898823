import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { Router } from '@angular/router';
import { AkiAuthService } from '@akilee/auth';
import { NbToastrService } from '@nebular/theme';
import { TranslocoService } from '@ngneat/transloco';
import { LocationService } from '../../admin/services/location/location.service';

@Component({
  selector: 'app-login-forgot',
  templateUrl: './login-forgot.component.html',
  styleUrls: ['../login/login.component.scss'],
})
export class LoginForgotComponent implements OnInit {
  isLoading = false;

  loginForm: FormGroup;

  loginFormFields = {
    email: ['', [Validators.required]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    stayConnected: false,
  };

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AkiAuthService,
    private notificationService: NbToastrService,
    private locationService: LocationService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    const lang = sessionStorage?.getItem('locale') || 'en';
    this.locationService.setLocale(lang);
    this.loginForm = this.fb.group(this.loginFormFields);
  }

  get email() {
    return this.loginForm.get('email');
  }

  onSubmit() {
    this.isLoading = true;
    const { email } = this.loginForm.value;
    this.authService.forgetPassword(email).subscribe(
      (data) => {
        this.notificationService.success(
          this.translocoService.translate<string>('login_forget.success_message'),
          this.translocoService.translate<string>('login.success_title'),
        );
        setTimeout(() => {
          this.router.navigate(['/admin/dashboard']);
        }, 5000);
      },
      (error: any) => {
        this.notificationService.danger(
          this.translocoService.translate<string>('login_forget.failure_message'),
          this.translocoService.translate<string>('login.error_title'),
        );
        this.isLoading = false;
      },
    );
  }
}
