import { Component, OnInit } from '@angular/core';
import { AkiAuthService } from '@akilee/auth';
import { NbToastrService } from '@nebular/theme';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { LocationService } from '../../admin/services/location/location.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;

  loginFormFields = {
    email: ['', [Validators.required]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    stayConnected: false,
  };

  isLoading = false;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AkiAuthService,
    private notificationService: NbToastrService,
    private locationService: LocationService,
    private translocoService: TranslocoService,
  ) {}

  ngOnInit() {
    const lang = sessionStorage.getItem('locale') || 'en';
    this.locationService.setLocale(lang);
    this.loginForm = this.fb.group(this.loginFormFields);
    this.authService.logout();
  }

  get email() {
    return this.loginForm?.get('email');
  }

  get password() {
    return this.loginForm?.get('password');
  }

  getDataLogin() {
    return this.loginForm?.value;
  }

  onSubmit() {
    this.isLoading = true;
    const { email, password } = this.getDataLogin();
    this.authService.login(email, password).subscribe(
      () => {
        this.isLoading = false;
        this.notificationService.success(
          this.translocoService.translate<string>('login.success_message'),
          this.translocoService.translate<string>('login.success_title'),
        );
        if (JSON.parse(localStorage.getItem('userToken')).user.locale) {
          this.locationService.setLocale(JSON.parse(localStorage.getItem('userToken')).user.locale);
        }
        this.router.navigate(['/admin/dashboard']);
      },
      () => {
        this.isLoading = false;
        this.notificationService.danger(
          this.translocoService.translate<string>('login.error_message'),
          this.translocoService.translate<string>('login.error_title'),
        );
      },
    );
  }
}
