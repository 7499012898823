import * as dayjs from 'dayjs';
import * as TempNavigationActions from '../actions/temporal-navigation.actions';
import { IDate } from '../models/date.model';
import 'dayjs/locale/fr';

const today: dayjs.Dayjs = dayjs();

const defaultItem: IDate = {
  type: 'MOIS',
  dateStartStr: today.startOf('month').format('YYYY-MM-DD HH:mm:ss'),
  dateEndStr: today.endOf('month').format('YYYY-MM-DD HH:mm:ss'),
};

const initialState = {
  selected_date: defaultItem,
};

export function tempNavReducer(state = initialState, action: TempNavigationActions.Actions) {
  if (action.type === TempNavigationActions.SET_TEMPORAL_NAVIGATION_RANGE) {
    return {
      ...state,
      selected_date: action.payload,
    };
  }
  return state;
}
