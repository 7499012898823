import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  NbAlertModule,
  NbButtonModule,
  NbCardModule,
  NbDatepickerModule,
  NbDialogModule,
  NbGlobalLogicalPosition,
  NbInputModule,
  NbLayoutModule,
  NbMenuModule,
  NbSidebarModule,
  NbSpinnerModule,
  NbThemeModule,
  NbToastrModule,
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { StoreModule } from '@ngrx/store';
import { AkiAuthModule } from '@akilee/auth';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AgGridModule } from 'ag-grid-angular';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeEn from '@angular/common/locales/en';
import localeAr from '@angular/common/locales/ar';
// import * as dayjs from 'dayjs';
// import 'dayjs/locale/fr';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgSelectModule } from '@ng-select/ng-select';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { tempNavReducer } from './admin/reducers/temporal-navigation.reducer';
import { nodeNavReducer } from './admin/reducers/node-navigation.reducer';
import { environment } from '../environments/environment';
import { LoginComponent } from './components/login/login.component';
import { headerReducer } from './admin/reducers/header.reducer';
import { GetNetworkService } from './admin/services/get-network/get-network.service';
import { ErrorHandlerService } from './services/error-handler/error-handler.service';
import { HttpErrorInterceptor } from './services/interceptors/http-error.interceptor.service';
import { BasicAuthInterceptor } from './services/interceptors/basic-http.interceptor.service';
import { VenteEnergieService } from './admin/services/vente-energie/vente-energie.service';
import { LoginForgotComponent } from './components/login-forgot/login-forgot.component';
import { TranslocoRootModule } from './transloco-root.module';
import { LocationService } from './admin/services/location/location.service';
import { CaisseService } from './admin/services/caisse/caisse.service';
import { SalesLogsService } from './admin/services/sales-logs/sales-logs.service';

registerLocaleData(localeFr, 'fr');
registerLocaleData(localeEn, 'en');
registerLocaleData(localeAr, 'ar');

@NgModule({
  declarations: [AppComponent, LoginComponent, LoginForgotComponent],
  imports: [
    AgGridModule.withComponents([]),
    AkiAuthModule.forRoot({
      serverUrl: `${environment.vendingServer}/api/v1`,
      loginFieldName: 'username',
      passwordFieldName: 'password',
      loginEndpoint: '/auth/login',
      checkTokenEndpoint: '/auth/verify-user',
      app: 'senami',
    }),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HttpClientModule,
    NbAlertModule,
    NbButtonModule,
    NbCardModule,
    NgSelectModule,
    NgxPermissionsModule.forRoot(),
    NbDialogModule.forRoot({
      hasBackdrop: true,
      closeOnBackdropClick: false,
      hasScroll: true,
    }),
    NbEvaIconsModule,
    NbInputModule,
    NbMenuModule.forRoot(),
    NbLayoutModule,
    NbSidebarModule.forRoot(),
    NbThemeModule.forRoot({ name: 'default' }),
    NbToastrModule.forRoot({
      position: NbGlobalLogicalPosition.TOP_END,
    }),
    StoreModule.forRoot({
      // @ts-ignore
      temp_navigation: tempNavReducer,
      // @ts-ignore
      node_navigation: nodeNavReducer,
      // @ts-ignore
      header_store: headerReducer,
    }),
    FormsModule,
    ReactiveFormsModule,
    NbSpinnerModule,
    NbDatepickerModule.forRoot(),
    TranslocoRootModule,
  ],
  providers: [
    GetNetworkService,
    ErrorHandlerService,
    VenteEnergieService,
    LocationService,
    CaisseService,
    SalesLogsService,
    { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'fr-FR' },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
