import { Action } from '@ngrx/store';
import { IDate } from '../models/date.model';

export const SET_TEMPORAL_NAVIGATION_RANGE = '[TEMPORAL_NAVIGATION] SET_RANGE';

export class SetTemporalRange implements Action {
  readonly type = SET_TEMPORAL_NAVIGATION_RANGE;

  constructor(public payload: IDate) {}
}

export type Actions = SetTemporalRange;
