import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import { ErrorHandlerService } from '../../../services/error-handler/error-handler.service';
import { environment } from '../../../../environments/environment';

@Injectable()
export class LocationService {
  constructor(
    private _http: HttpClient,
    private _errorHandler: ErrorHandlerService,
    private translocoService: TranslocoService,
  ) {}

  getAllLocalization(): Observable<any> {
    return this._http
      .get(`${environment.vendingServer}/localization`)
      .pipe(catchError(this._errorHandler.handleError));
  }

  setLocale(locale: string) {
    let locales = JSON.parse(sessionStorage.getItem('locales'));
    if (locales == null) {
      this.getAllLocalization().subscribe((data) => {
        sessionStorage.setItem('locales', JSON.stringify(data));
        locales = JSON.parse(sessionStorage.getItem('locales'));
        this.translocoService.setActiveLang(locale);
        sessionStorage.setItem('locale', locale);
        this.translocoService.setTranslation(locales[locale], locale);
      });
    } else {
      this.translocoService.setActiveLang(locale);
      sessionStorage.setItem('locale', locale);
      this.translocoService.setTranslation(locales[locale], locale);
    }
  }
}
