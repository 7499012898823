import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable()
export class ErrorHandlerService {
  // TODO > Refactor
  // eslint-disable-next-line class-methods-use-this
  handleError(err: any) {
    return throwError(err);
  }
}
