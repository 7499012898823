import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { ErrorHandlerService } from '../../../services/error-handler/error-handler.service';
import { getHttpOptions } from '../../../../utils/http-utils';

@Injectable()
export class GetNetworkService {
  getNetworkUrl = `${environment.uipServer}/api/v1/network`;

  constructor(private _http: HttpClient, private _errorHandler: ErrorHandlerService) {}

  httpOptions = getHttpOptions();

  getNetwork(): Observable<any> {
    return this._http
      .get(this.getNetworkUrl, this.httpOptions)
      .pipe(catchError(this._errorHandler.handleError));
  }

  getDelegations(): Observable<any[]> {
    return this._http
      .get<any[]>(`${this.getNetworkUrl}/delegation`, this.httpOptions)
      .pipe(catchError(this._errorHandler.handleError));
  }

  getDelegationById(delegationId: string): Observable<any> {
    return this._http
      .get(`${this.getNetworkUrl}/delegation?delegation=${delegationId}`, this.httpOptions)
      .pipe(catchError(this._errorHandler.handleError));
  }

  getAgences(): Observable<any> {
    return this._http
      .get(`${this.getNetworkUrl}/agence`, this.httpOptions)
      .pipe(catchError(this._errorHandler.handleError));
  }

  getAgencesByDelegationId(delegationId: string): Observable<any[]> {
    return this._http
      .get<any[]>(`${this.getNetworkUrl}/agence?delegation=${delegationId}`, this.httpOptions)
      .pipe(catchError(this._errorHandler.handleError));
  }

  getAgenceById(agenceId: string): Observable<any> {
    return this._http
      .get(`${this.getNetworkUrl}/agence?agence=${agenceId}`, this.httpOptions)
      .pipe(catchError(this._errorHandler.handleError));
  }

  getMeters(): Observable<any> {
    return this._http
      .get(`${this.getNetworkUrl}/meter`, this.httpOptions)
      .pipe(catchError(this._errorHandler.handleError));
  }

  getMetersByAgenceId(agenceId: string): Observable<any[]> {
    return this._http
      .get<any[]>(`${this.getNetworkUrl}/meter?agence=${agenceId}`, this.httpOptions)
      .pipe(catchError(this._errorHandler.handleError));
  }

  getMeterById(meterId: string): Observable<any> {
    return this._http
      .get(`${this.getNetworkUrl}/meter?meter_number=${meterId}`, this.httpOptions)
      .pipe(catchError(this._errorHandler.handleError));
  }

  getMeterStatus(meterUuid: string): Observable<any> {
    return this._http
      .get(`${environment.uipServer}/api/v1/hex-api/meters/status/${meterUuid}`, this.httpOptions)
      .pipe(catchError(this._errorHandler.handleError));
  }

  getMeterPuissanceLimite(meterUuid: string): Observable<any> {
    return this._http
      .get(
        `${environment.uipServer}/api/v1/hex-api/on-demand/get-puissance-limite?meter_uuid=${meterUuid}`,
        this.httpOptions,
      )
      .pipe(catchError(this._errorHandler.handleError));
  }

  searchMeter(filter: string): Observable<any> {
    return this._http
      .get<any[]>(`${this.getNetworkUrl}/recherche?meter_number=${filter}`, this.httpOptions)
      .pipe(catchError(this._errorHandler.handleError));
  }

  getOnlinePercentage(nodeType?: string, nodeId?: string): Observable<any> {
    let params = '';
    if (nodeId && nodeType) {
      params = `?node_type=${nodeType}&node_id=${nodeId}`;
    } else {
      params = '?node_id=0';
    }
    return this._http
      .get<any[]>(`${this.getNetworkUrl}/pourcentage-inline${params}`, this.httpOptions)
      .pipe(catchError(this._errorHandler.handleError));
  }
}
