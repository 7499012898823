import * as NodeActions from '../actions/node-navigation.actions';
import { Node } from '../models/node.model';

const defaultItem: Node = {
  id: '',
  type: 'ALL',
  data: null,
};

const initialState = {
  selected_node: defaultItem,
};

export function nodeNavReducer(state = initialState, action: NodeActions.Actions) {
  if (action.type === NodeActions.SELECT_NODE) {
    return {
      ...state,
      selected_node: action.payload,
    };
  }
  return state;
}
